import './App.css';
import Routing from './routes'
import { BrowserRouter as Router, Link } from "react-router-dom";
import TimeList from './components/TimeList';


function App() {
  return (
    <Router>
      <div className="flex-container">
        <div className="flex-item-left">
          <Routing />
        </div>

        <div className="flex-item-right">
          <div style={{ padding: "20px" }}>
            <div className="item-header">
              หวยล่าสุด
            </div>
            <TimeList/>
          </div>

          <div style={{ padding: "20px" }}>
            <div className="item-header">
              สถิติหวยตามวันในสัปดาห์
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/day/1">วันอาทิตย์</Link></div>
              <div className="item"><Link to="/day/2">วันจันทร์</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/day/3">วันอังคาร</Link></div>
              <div className="item"><Link to="/day/4">วันพุธ</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/day/5">วันพฤหัสบดี</Link></div>
              <div className="item"><Link to="/day/6">วันศุกร์</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/day/7">วันเสาร์</Link></div>
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            <div className="item-header">
              สถิติหวยตามเดือน
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/month/1">มกราคม</Link></div>
              <div className="item"><Link to="/month/2">กุมภาพันธ์</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/month/3">มีนาคม</Link></div>
              <div className="item"><Link to="/month/4">เมษายน</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/month/5">พฤษภาคม</Link></div>
              <div className="item"><Link to="/month/6">มิถุนายน</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/month/7">กรกฎาคม</Link></div>
              <div className="item"><Link to="/month/8">สิงหาคม</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/month/9">กันยายน</Link></div>
              <div className="item"><Link to="/month/10">ตุลาคม</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/month/11">พฤศจิกายน</Link></div>
              <div className="item"><Link to="/month/12">ธันวาคม</Link></div>
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            <div className="item-header">
              สถิติหวยตามวันที่ออก
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/dayth/01">วันที่ 1</Link></div>
              <div className="item"><Link to="/dayth/02">วันที่ 2</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/dayth/16">วันที่ 16</Link></div>
              <div className="item"><Link to="/dayth/17">วันที่ 17</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/dayth/30">วันที่ 30</Link></div>
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            <div className="item-header">
              สถิติหวยย้อนหลัง
            </div>
            <div className="flex-container">
              <div className="item">1 ปี</div>
              <div className="item">2 ปี</div>
            </div>
            <div className="flex-container">
              <div className="item">3 ปี</div>
              <div className="item">4 ปี</div>
            </div>
            <div className="flex-container">
              <div className="item">5 ปี</div>
              <div className="item">6 ปี</div>
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            <div className="item-header">
              สถิติหวยตามปี
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/year/2564">ปี 2564</Link></div>
              <div className="item"><Link to="/year/2563">ปี 2563</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/year/2562">ปี 2562</Link></div>
              <div className="item"><Link to="/year/2561">ปี 2561</Link></div>
            </div>
            <div className="flex-container">
              <div className="item"><Link to="/year/2560">ปี 2560</Link></div>
              <div className="item"><Link to="/year/2559">ปี 2559</Link></div>
            </div>
          </div>

        </div>


      </div>
    </Router>
  );
}

export default App;


