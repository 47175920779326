import React from 'react';

// สร้าง Component
const Title = (props) => {
  const { textTitle,textDes, viewStyle } = styles;

  return (
    <div style={viewStyle}>
        <div style={textTitle}>{props.name}</div>
        <div style={textDes}>{props.des}</div>
    </div>
  );
};


const styles = {
  viewStyle: {
    padding: 15,
    shadowOpacity: 0.2,
    margin:10,
  },
  textTitle: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  textDes: {
    fontSize: 16
  }
};

export default Title;
