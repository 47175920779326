import React from 'react';

// สร้าง Component
const Card = (props) => {
  const { textStyle, viewStyle } = styles;

  return (
    <div style={viewStyle}>
        <h1 style={textStyle}>{props.name}</h1>
        <p style={textStyle}>{props.des}</p>
    </div>
  );
};


const styles = {
  viewStyle: {
    backgroundColor: '#418bb9',
    borderRadius: '7px',
    width:'90%',
    padding: 15,
    shadowOpacity: 0.2,
    margin:10

  },
  textStyle: {
    color:'#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 20
  }
};

export default Card;
