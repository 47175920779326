

export function day(x) {
    let y = '';
    if (x.length > 2) {
        x = x.substring(0, 2);
    }
    y = parseInt(x);

    return y;
}

export function year(x) {
    let y = '';
    if (x.length > 2) {
        x = x.substring(4);
    }
    y = parseInt(x);

    return y;
}

export function dayName(x) {
    let id = '';
    let y = '';
    // console.log('day input ' + x);
    id = parseInt(x);
    // console.log(id);

    switch (id) {
        case 1:
            y = "อาทิตย์";
            break;
        case 2:
            y = "จันทร์";
            break;
        case 3:
            y = "อังคาร";
            break;
        case 4:
            y = "พุธ";
            break;
        case 5:
            y = "พฤหัสบดี";
            break;
        case 6:
            y = "ศุกร์";
            break;
        case 7:
            y = "เสาร์";
            break;
        default:
            y = "NAN";
            break;
    }

    return y;
}

export function monthName(x) {
    // console.log('month input ' + x);
    if (x.length > 2) {
        x = x.substring(2, 4);
        // console.log('x after sub ' + x);
    }
    let id = '';
    let y = '';

    id = parseInt(x);
    // console.log(id);

    switch (id) {
        case 1:
            y = "มกราคม";
            break;
        case 2:
            y = "กุมภาพันธ์";
            break;
        case 3:
            y = "มีนาคม";
            break;
        case 4:
            y = "เมษายน";
            break;
        case 5:
            y = "พฤษภาคม";
            break;
        case 6:
            y = "มิถุนายน";
            break;
        case 7:
            y = "กรกฎาคม";
            break;
        case 8:
            y = "สิงหาคม";
            break;
        case 9:
            y = "กันยายน";
            break;
        case 10:
            y = "ตุลาคม";
            break;
        case 11:
            y = "พฤศจิกายน";
            break;
        case 12:
            y = "ธันวาคม";
            break;
        default:
            y = "NAN";
            break;
    }

    return y;
}