import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './pages/Home'
import Day from './pages/Day';
import Month from './pages/Month';
import Year from './pages/Year';
import Time from './pages/Time';
import Dayth from './pages/Dayth';

const routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/time/:id" component={Time} />
    <Route exact path="/day/:id" component={Day} />
    <Route exact path="/month/:id" component={Month} />
    <Route exact path="/year/:id" component={Year} />
    <Route exact path="/dayth/:id" component={Dayth} />
  </Switch>
)

export default routes;