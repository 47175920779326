import React from 'react';
import Title from '../components/title';
import { day,monthName,year } from '../utils.js';
import { withRouter } from "react-router";
const axios = require('axios');
const https = require('https');
class Home extends React.Component {

  constructor() {
    super();
    this.state = { isLoaded: false, id: '', posts: [],lastlot:'' };
  }

  fetchData(id) {
    const agent  = new https.Agent({
      rejectUnauthorized: true,
    })
    
    const api = process.env.REACT_APP_API+'api/lotto/date?date=' + id;
    axios.get(api,{ httpsAgent:agent }).then(res => {
      const data = res.data.Prizes.map(obj => obj);
      this.setState({ id: id, isLoaded: true, posts: data });
    });

  

  }

  getLastLot(){
    const api = process.env.REACT_APP_API+'api/lotto';
    axios.get(api,).then(res => {
      const data = res.data.map(obj => obj);
      // console.log(data[0])
      this.setState({ lastlot: data[0] });
      // console.log(data[0]);
      this.fetchData(data[0]);
    });

  }


  componentDidMount() {
    this.getLastLot();
    // let id = '16072564';
    // console.log(id);
    // this.fetchData(id);

    // this.unlisten = this.props.history.listen((location, action) => {
    //   console.log("on route change home", location);
    //   console.log(action, location.pathname, location.state);
    //   if (location.pathname.includes("")) {
    //     id = '16072564';
    //     console.log(id);
    //     this.fetchData(id);
    //   }
    // });

  }

  componentWillUnmount() {
    // this.unlisten();
  }


  render() {
    if (!this.state.isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <Title name="ตรวจหวย ตรวจสลาก" des="" />
          <div className="center">
            <div>
              <h1 style={{ color: "#3c8b24", fontSize: "5vw" }}>ผลสลากกินแบ่งรัฐบาล</h1>
              <div>งวดประจำวันที่ {day(this.state.id)} {monthName(this.state.id)} {year(this.state.id)}</div>
            </div>
            <div className="flex-container">
              <div style={{ flex: "70%", padding: "10px" }}>
                <div style={{ backgroundColor: "#3c8b24", color: "#FFF", padding: "10px" }}>
                  รางวัลที่ 1
                </div>
                {this.state.posts.filter(prizes => prizes.Type === 'FR').map((filteredPrizes, i) => (
                  <div className="bold" style={{ fontSize: "10vw" }} key={i}>
                    {filteredPrizes.Number}
                  </div>
                ))}
              </div>
              <div style={{ flex: "30%", padding: "10px" }}>
                <div style={{ backgroundColor: "#5aa633", color: "#FFF", padding: "10px" }}>
                  เลขท้าย 2 ตัว
                </div>
                {this.state.posts.filter(prizes => prizes.Type === 'A2').map((filteredPrizes, i) => (
                  <div className="bold" style={{ fontSize: "10vw", color: "#8b7427" }} key={i}>
                    {filteredPrizes.Number}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-container">
              <div style={{ flex: "50%", padding: "10px" }}>
                <div style={{ backgroundColor: "#5aa633", color: "#FFF", padding: "10px" }}>
                  เลขหน้า 3 ตัว
                </div>
                <div style={{ display: "flex" }}>
                  {this.state.posts.filter(prizes => prizes.Type === 'B3').map((filteredPrizes, i) => (
                    filteredPrizes.Number.map((number, i) => (
                      <div className="bold" style={{ flex: "50%", fontSize: "7vw" }} key={i}>
                        {number}
                      </div>
                    ))
                  ))}
                </div>
              </div>
              <div style={{ flex: "50%", padding: "10px" }}>
                <div style={{ backgroundColor: "#5aa633", color: "#FFF", padding: "10px" }}>
                  เลขท้าย 3 ตัว
                </div>
                <div style={{ display: "flex" }}>
                  {this.state.posts.filter(prizes => prizes.Type === 'A3').map((filteredPrizes, i) => (
                    filteredPrizes.Number.map((number, i) => (
                      <div className="bold" style={{ flex: "50%", fontSize: "7vw" }} key={i}>
                        {number}
                      </div>
                    ))
                  ))}
                </div>
              </div>
            </div>
            <p></p>
            <div style={{ backgroundColor: "#5aa633", color: "#FFF", padding: "10px" }}>
              รางวัลข้างเคียง รางวัลที่ 1
            </div>
            <div style={{ display: "flex" }}>
              {this.state.posts.filter(prizes => prizes.Type === 'NE').map((filteredPrizes) => (
                filteredPrizes.Number.map((number, i) => (
                  <div className="bold" style={{ flex: "50%", fontSize: "4vw" }} key={i}>
                    {number}
                  </div>
                ))
              ))}


            </div>
            <p></p>
            <div style={{ backgroundColor: "#5aa633", color: "#FFF", padding: "10px" }}>
              รางวัลที่ 2
            </div>
            <div style={{ display: "flex" }}>
              {this.state.posts.filter(prizes => prizes.Type === 'SE').map((filteredPrizes) => (
                filteredPrizes.Number.map((number, i) => (
                  <div className="bold" style={{ flex: "20%", fontSize: "20px" }} key={i}>
                    {number}
                  </div>
                ))
              ))}

            </div>
            <p></p>
            <div>
              <div style={{ backgroundColor: "#5aa633", color: "#FFF", padding: "10px" }}>
                รางวัลที่ 3
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.posts.filter(prizes => prizes.Type === 'TH').map((filteredPrizes) => (
                  filteredPrizes.Number.map((number, i) => (
                    <div className="bold" style={{ flex: "20%", fontSize: "20px" }} key={i}>
                      {number}
                    </div>
                  ))
                ))}
              </div>
            </div>
            <p></p>
            <div>
              <div style={{ backgroundColor: "#5aa633", color: "#FFF", padding: "10px" }}>
                รางวัลที่ 4
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.posts.filter(prizes => prizes.Type === 'FO').map((filteredPrizes) => (
                  filteredPrizes.Number.map((number, i) => (
                    <div className="bold" style={{ flex: "20%", fontSize: "20px" }} key={i}>
                      {number}
                    </div>
                  ))
                ))}
              </div>
            </div>
            <p></p>
            <div>
              <div style={{ backgroundColor: "#5aa633", color: "#FFF", padding: "10px" }}>
                รางวัลที่ 5
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.posts.filter(prizes => prizes.Type === 'FI').map((filteredPrizes) => (
                  filteredPrizes.Number.map((number, i) => (
                    <div className="bold" style={{ flex: "20%", fontSize: "20px" }} key={i}>
                      {number}
                    </div>
                  ))
                ))}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

}

export default withRouter(Home);