import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { day,monthName,year } from '../utils.js';

const axios = require('axios');

class TimeList extends Component {

    constructor() {
        super();
        this.state = { isLoaded: false, posts: [] };
    }

    componentDidMount() {

        const api = process.env.REACT_APP_API + 'api/lotto'
        axios.get(api).then(res => {
            const data = res.data.map(obj => obj);
            // console.log(data);
            this.setState({ isLoaded: true, posts: data });
        });

    }


    render() {
        if (!this.state.isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <div className="flex-container" style={{ flexWrap: "wrap" }}>
                        {this.state.posts.filter((data, i) => i < 20).map((data,index) => (
                            <div style={{ flex: "50%" }} key={index}><Link to={'/time/' + data}>{day(data)} {monthName(data)} {year(data)}</Link></div>
                        ))}
                    </div>
                </div>
            );
        }
    }
}

export default TimeList;