import React from 'react'
// import { useParams } from "react-router-dom";
import Card from '../components/card';
import Title from '../components/title';
import { day,dayName,monthName,year } from '../utils.js';

const axios = require('axios');

class Day extends React.Component {

  constructor() {
    super();
    this.state = { isLoaded: false, id: '', posts: [] };
  }


  fetchData(id) {
    const api = process.env.REACT_APP_API + 'api/lotto/dayWeek?dayWeek=' + id;
    axios.get(api).then(res => {
      this.setState({ id: id, isLoaded: true, posts: res.data });
    });


  }

  componentDidMount() {
    // this.fetchData();
    let id = this.props.match.params.id;

    this.fetchData(id);

    this.unlisten = this.props.history.listen((location, action) => {
      console.log(action, location.pathname, location.state);
      if (location.pathname.includes("day")) {
        id = location.pathname.substring(5);
        this.fetchData(id);
      }
    });

  }

  componentWillUnmount() {
    this.unlisten();
  }


  render() {

    const { textTd } = styles;

    if (!this.state.isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <Title name={'ผลการออกสลากรางวัล ย้อนหลัง 20 ปี เฉพาะที่ตรงกับวัน' + dayName(this.props.match.params.id)} des="นับตั้งแต่งวดวันที่ 16 มกราคม 2544 จนถึงงวดปัจจุบัน รวมสถิติหวยออกวันจันทร์ทั้งหมด 72 งวด" />
          <div className="flex-container">
            <Card name="สถิติหวย เลขท้าย 3 ตัวบน" des="ดูผลการวิเคราะห์สถิติเลขท้าย 3 ตัวบน ที่ออกวันจันทร์" />
            <Card name="สถิติเลขหน้า 3 ตัว" des="ดูผลการวิเคราะห์สถิติเลขหน้า 3 ตัว ที่ออกวันจันทร์" />
          </div>
          <div className="flex-container">
            <Card name="สถิติเลขท้าย 3 ตัว" des="ดูผลการวิเคราะห์สถิติเลขท้าย 3 ตัว ที่ออกวันจันทร์" />
            <Card name="สถิติเลขท้าย 2 ตัว" des="ดูผลการวิเคราะห์สถิติเลขท้าย 2 ตัว ที่ออกวันจันทร์" />
          </div>
          <div>
           
            <br/>
            <div>วัน{dayName(this.props.match.params.id)}</div>
            <div>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>วันที่</th>
                    <th>รางวัลที่ 1</th>
                    <th colSpan='2'>เลขหน้า 3 ตัว</th>
                    <th colSpan='2'>เลขท้าย 3 ตัว</th>
                    <th>เลขท้าย 2 ตัว</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(this.state.posts).map((key, index) =>
                      <tr key={index} style={textTd}>
                        <td style={{ textAlign: 'left' }}>{ day(this.state.posts[key].Date) } { monthName(this.state.posts[key].Date) } { year(this.state.posts[key].Date) }</td>
                        <td>{ this.state.posts[key].Type.FR[0] }</td>
                        <td>{ this.state.posts[key].Type.A3[0] }</td>
                        <td>{ this.state.posts[key].Type.A3[1] }</td>
                        <td>{ (this.state.posts[key].Type.A3[2] !== undefined) ? this.state.posts[key].Type.A3[2]:(this.state.posts[key].Type.B3 !== undefined) ? this.state.posts[key].Type.B3[0]:'' }</td>
                        <td>{ (this.state.posts[key].Type.A3[3] !== undefined) ? this.state.posts[key].Type.A3[3]:(this.state.posts[key].Type.B3 !== undefined) ? this.state.posts[key].Type.B3[1]:'' }</td>
                        <td>{ this.state.posts[key].Type.A2[0] }</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
  }

};

const styles = {
  textTd: {
    textAlign: 'center',
  },

};


export default Day;