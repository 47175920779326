import React from 'react'
import { withRouter } from "react-router";
// import { useParams } from "react-router-dom";
import Card from '../components/card';
import Title from '../components/title';
import { day,monthName,year } from '../utils.js';

const axios = require('axios');

class Month extends React.Component {

  constructor() {
    super();
    this.state = { isLoaded: false, id: '', posts: [] };
  }


  fetchData(id) {
    id = ('0' + id).slice(-2);
    const api = process.env.REACT_APP_API + 'api/lotto/month?month=' + id;
    axios.get(api).then(res => {
      
      this.setState({ id: id, isLoaded: true, posts: res.data });

      console.log(this.state.posts)

    });
  }

  componentDidMount() {
    let id = this.props.match.params.id;

    this.fetchData(id);

    this.unlisten = this.props.history.listen((location, action) => {
      console.log("on route change", location);
      if (location.pathname.includes("month")) {
        id = location.pathname.substring(7);
        console.log('month',id);
        this.fetchData(id);
      }
    });

  }

  componentWillUnmount() {
    this.unlisten();
  }


  render() {

    const { textTd } = styles;

    if (!this.state.isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <Title name={'ผลการออกสลากรางวัล ย้อนหลัง เฉพาะที่ตรงกับเดือน' + monthName(this.props.match.params.id)} des="นับตั้งแต่งวดวันที่ 16 มกราคม 2544 จนถึงงวดปัจจุบัน รวมสถิติหวยออกวันจันทร์ทั้งหมด 72 งวด" />
          <div className="flex-container">
            <Card name="สถิติหวย เลขท้าย 3 ตัวบน" des="ดูผลการวิเคราะห์สถิติเลขท้าย 3 ตัวบน ที่ออกวันจันทร์" />
            <Card name="สถิติเลขหน้า 3 ตัว" des="ดูผลการวิเคราะห์สถิติเลขหน้า 3 ตัว ที่ออกวันจันทร์" />
          </div>
          <div className="flex-container">
            <Card name="สถิติเลขท้าย 3 ตัว" des="ดูผลการวิเคราะห์สถิติเลขท้าย 3 ตัว ที่ออกวันจันทร์" />
            <Card name="สถิติเลขท้าย 2 ตัว" des="ดูผลการวิเคราะห์สถิติเลขท้าย 2 ตัว ที่ออกวันจันทร์" />
          </div>
          <div>
           
            <br/>
            <div>เดือน{monthName(this.props.match.params.id)}</div>
            <div>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>วันที่</th>
                    <th>รางวัลที่ 1</th>
                    <th colSpan='2'>เลขหน้า 3 ตัว</th>
                    <th colSpan='2'>เลขท้าย 3 ตัว</th>
                    <th>เลขท้าย 2 ตัว</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.posts.map((data,index) => (
                    <tr key={index} style={textTd}>
                      <td style={{ textAlign: 'left' }}>{ day(data.Group) } { monthName(data.Group) } { year(data.Group) }</td>
                      <td>{ data.FR }</td>
                      <td>{ data.B31 }</td>
                      <td>{ data.B32 }</td>
                      <td>{ data.A31 }</td>
                      <td>{ data.A32 }</td>
                      <td>{ data.A2 }</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
  }

};

const styles = {
  textTd: {
    textAlign: 'center',
  },

};

export default withRouter(Month);